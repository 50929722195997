import React, { createContext, useState, useEffect } from 'react';
import axios from "axios";
// import { saveCategoriesToLocalStorage, loadCategoriesFromLocalStorage } from './localStorageUtil'; 

export const CategoriesContext = createContext();

export const CategoriesProvider = ({ children }) => {
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const res = await axios.get(`/api/view-category`);
                if (res.status === 200) {
                    setCategories(res.data.categories);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchCategories();
    }, []);

    return (
        <CategoriesContext.Provider value={{ categories }}>
            {children}
        </CategoriesContext.Provider>
    );
};
